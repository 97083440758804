define(['app'], function (app) {

  const multiProfileCard = function() {

    const component = {};

    component.config = {
      selectors: {
        removeCardItem: '[data-item="remove-card-item"]',
        confirmationModal: '.profileCard_confirmation',
        confirmationModalSubmit: '[data-item="modal-submit"]',
        confirmationModalCancel: '[data-item="modal-cancel"]'
      },
      attributes: {
        useConfirmation: 'data-confirmation'
      }
    };

    component.init = (element) => {
      component.element = element;
      component.removeCardItem = component.element.querySelector(component.config.selectors.removeCardItem);
      component.useConfirmation = component.removeCardItem.getAttribute(component.config.attributes.useConfirmation);
      component.confirmationModalCancel = document.querySelector(component.config.selectors.confirmationModalCancel);
      component.confirmationModalSubmit = document.querySelector(component.config.selectors.confirmationModalSubmit);
      component.cardItemForm = component.removeCardItem.parentNode;

      component.addEventListeners();

      return component;
    };

    component.addEventListeners = () => {
      component.removeCardItem.addEventListener('click', component.removeProfileCard);

      if (component.useConfirmation === 'true') {
        component.confirmationModalCancel.addEventListener('click', component.closeConfirmationModal);
      }
    };

    component.removeProfileCard = (e) => {
      e.preventDefault();

      if (component.useConfirmation !== 'true') {
        return component.destroyProfileCard();
      }

      component.openConfirmationModal();
    };

    component.openConfirmationModal = () => {
      document.querySelector(component.config.selectors.confirmationModal).classList.add('profileCard_confirmation--show');
      document.querySelector(component.config.selectors.confirmationModalSubmit).onclick = component.destroyProfileCard;
    };

    component.closeConfirmationModal = (e) => {
      e.preventDefault();

      document.querySelector(component.config.selectors.confirmationModal).classList.remove('profileCard_confirmation--show');
    };

    component.destroyProfileCard = () => {
      component.cardItemForm.submit();
    };

    return component;

  };

  return multiProfileCard;
});
